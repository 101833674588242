import excavlogo from "../asset/img/excavlogo.webp";

export function Header() {
  return (
    <div className="flex items-center justify-between border-b-2 border-gray-300 bg-gray-270">
      <div className="flex-shrink-0">
        <img className="w-[100px] h-[80px]" alt="Logo" src={excavlogo}></img>
      </div>

      <nav className="flex-grow">
        <ul className="flex justify-center space-x-10 text-lg">
          <li>
            <a href="#home" className="hover:text-gray-700">
              Hem
            </a>
          </li>
          <li>
            <a href="#about" className="hover:text-gray-700">
              Om oss
            </a>
          </li>
          <li>
            <a href="#contact" className="hover:text-gray-700">
              Kontakt
            </a>
          </li>
          <li>
            <a href="#faq" className="hover:text-gray-700">
              Frågor & Svar
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
}
