import excavator from "../asset/img/excavv.webp";
import { Button } from "../components/Button";

export function ContactInfo() {
  return (
    <>
      <div className="flex justify-center">
        <div className="">
          <img src={excavator} alt="w-[500px]" />
        </div>
        <div className="flex flex-col justify-center">
          <form>
            <div className="flex items-center">
              <label
                htmlFor="name"
                className="block text-2xl leading-6 text-gray-900"
              >
                Namn:
              </label>
              <div className="mt-2 px-10">
                <input
                  id="name"
                  name="name"
                  type="name"
                  required
                  className="block w-[300px] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="flex items-center">
              <label
                htmlFor="address"
                className="block text-2xl leading-6 text-gray-900"
              >
                Address:
              </label>
              <div className="mt-2 px-10">
                <input
                  id="address"
                  name="address"
                  type="address"
                  required
                  className="block w-[300px] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="flex items-center">
              <label
                htmlFor="email"
                className="block text-2xl leading-6 text-gray-900"
              >
                Telefon:
              </label>
              <div className="mt-2 px-10">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="block w-[300px] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="flex items-center">
              <label
                htmlFor="email"
                className="block text-2xl font-normal leading-6 text-gray-900"
              >
                Email:
              </label>
              <div className="mt-2 px-10">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="block w-[300px] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="flex justify-center">
        <p className="text-3xl">
          När jag beställer godkänner jag{" "}
          <span className="underline text-blue-500">användaravtalet…</span>
        </p>
      </div>
      <div className="flex justify-center mt-10">
        <Button className="text-xl text-black border-black px-4 bg-green-500 py-1 border-2 mt-2">
          Beställ med BankID!
        </Button>
      </div>
    </>
  );
}
