import { Header } from "./pages/Header";
import { Landing } from "./pages/Landing";
import { Equipment } from "./pages/Equipment";
import { Schedule } from "./pages/Schedule";
import { RentPeriod } from "./pages/RentPeriod";
import { ContactInfo } from "./pages/ContactInfo";
import { useState } from "react";

function App() {
  const [currentPage, setCurrentPage] = useState("landing");
  const [postalCode, setPostalCode] = useState<string | null>(null);

  const handleNavigation = (page: any) => {
    setCurrentPage(page);
  };

  return (
    <>
      <Header />
      <div className="app-container px-5 py-5">
        {currentPage === "landing" && (
          <Landing onNavigate={() => handleNavigation("equipment")} />
        )}
        {currentPage === "equipment" && (
          <Equipment
            onNavigate={() => handleNavigation("schedule")}
            setPostalCode={setPostalCode}
            postalCode={postalCode}
          />
        )}
        {currentPage === "schedule" && (
          <Schedule
            onNavigate={() => handleNavigation("rentPeriod")}
            postalCode={postalCode}
          />
        )}
        {currentPage === "rentPeriod" && (
          <RentPeriod onNavigate={() => handleNavigation("contactInfo")} />
        )}
        {currentPage === "contactInfo" && <ContactInfo />}
      </div>
    </>
  );
}

export default App;
