import excavator from "../asset/img/excavv.webp";
import { useState } from "react";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker, Range } from "react-date-range";
import { addDays } from "date-fns";

export function Schedule({
  onNavigate,
  postalCode,
}: {
  onNavigate: () => void;
  postalCode: string | null;
}) {
  const [ranges, setRanges] = useState<Range[]>([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  return (
    <>
      <div className="flex justify-center text-gray-500 mt-5" />
      <div className=" px-24 flex">
        <div className=" ml-10">
          <img src={excavator} alt="" className="w-3/3"></img>
          <h1 className="text-2xl ml-10">Everun ERE12E</h1>
          <h1 className="text-2xl ml-10">Helelektrisk grävmaskin</h1>
          <h1 className="text-2xl ml-10">1050kg</h1>
        </div>
        <div className="py-10">
          <p className="text-4xl">
            Perfekt! Vi levererar till {postalCode}, Snabbköping. När behöver du
            maskinen?
          </p>
          <DateRangePicker
            onChange={(item) => setRanges([item.selection])}
            showPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={ranges}
            direction="horizontal"
            className="mt-10"
            disabledDates={[new Date("2024-01-15")]}
            minDate={addDays(new Date(), 1)}
          />
        </div>
      </div>
      <div className="flex justify-end px-24">
        <button
          type="submit"
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
        >
          Next
        </button>
      </div>
    </>
  );
}
