import excavator from "../asset/img/excavv.webp";
import toast, { Toaster } from "react-hot-toast";

interface EquipmentProps {
  onNavigate: () => void;
  setPostalCode: (value: string | null) => void;
  postalCode: string | null;
}

export function Equipment({
  onNavigate,
  setPostalCode,
  postalCode,
}: EquipmentProps) {
  const fetchPostalCode = async (postalCode: string) => {
    try {
      const res = await fetch(`http://localhost:8877/postalcode/${postalCode}`);
      const data = await res.json();

      if (data.serving === true) {
        onNavigate();
      } else {
        toast.error("Tyvärr så servar vi inte ditt område ännu");
      }
    } catch (error) {
      console.log(error);
      toast.error("Det gick inte att hämta postnummer.");
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!postalCode) return;
    await fetchPostalCode(postalCode);
  };

  return (
    <>
      <Toaster />
      <div className=" px-24 flex">
        <div className=" ml-10">
          <img src={excavator} alt="" className=" w-max"></img>
          <h1 className="text-2xl ml-10">Everun ERE12E</h1>
          <h1 className="text-2xl ml-10">Helelektrisk grävmaskin</h1>
          <h1 className="text-2xl ml-10">1050kg</h1>
        </div>
        <div className="py-10">
          <p className="text-4xl text-gray-500">
            Vad roligt att du vill hyra en grävmaskin!
          </p>
          <p className="text-4xl text-center mt-5 text-gray-500">
            Vilket postnummer bor du på?
          </p>
          <div className="flex justify-center">
            <form onSubmit={handleSubmit}>
              <div className="flex justify-center mt-10">
                <div className="flex justify-center items-center space-x-4">
                  <input
                    id="post-code"
                    type="number"
                    maxLength={5}
                    placeholder="XXXXX"
                    className="appearance-none w-full px-4 py-3 border border-gray-300 text-gray-700 leading-tight focus:outline-none rounded-md shadow-sm transition duration-300 ease-in-out focus:border-green-500 focus:ring focus:ring-green-200"
                    onChange={(e) => setPostalCode(e.target.value)}
                  />
                  <button
                    type="submit"
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
                  >
                    Enter
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
