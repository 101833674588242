import { IconX } from "../../asset/icon";
import excavator from "../../asset/img/excavv.webp";

interface props {
  close: () => void;
}
export default function StartUpCost({ close }: props) {
  return (
    <div className="">
      <div className="absolute inset-0 flex justify-center items-center ">
        <div className="py-2 px-3 bg-white border-black border-2 rounded-xl text-base w-[700px] h-[400px]">
          <div className="flex justify-end font-bold mt-[25px] px-2">
            <div
              className="px-2 py-2 border border-black rounded-2xl bg-gray-200"
              onClick={close}
            >
              <IconX />
            </div>
          </div>
          <div className="">
            <p className="text-2xl px-5">I Etableringskostnaden ingår:</p>
            <div className="px-10 mt-7">
              <p className="text-2xl">
                &bull; <span className="font-bold">Leverans</span> av maskin och
                tillbehör till och från din fastighet
              </p>
              <p className="text-2xl">
                &bull; Praktisk <span className="font-bold">instruktion</span>{" "}
                och genomgång av maskinen vid leverans.
              </p>
              <p className="text-2xl">
                &bull; <span className="font-bold">Tvätt</span>av maskinen efter
                avslutad hyrtid.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
