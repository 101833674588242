import excavator from "../asset/img/excavv.webp";
import bulldozer from "../asset/img/bulldozer.avif";
import { Button } from "../components/Button";
import { useState } from "react";
import StartUpCost from "../components/modal/startupcost";

export function RentPeriod({ onNavigate }: { onNavigate: () => void }) {
  const [startupcost, setstartupcost] = useState(false);

  return (
    <>
      <div className="flex justify-center text-gray-500 mt-5">
        {/* <p className="text-4xl text-center w-2/3">Vad roligt att du vill hyra en grävmaskin!
                </p> */}
      </div>
      <div className=" px-20 flex">
        <div className=" ml-10">
          <img src={excavator} alt="" className="w-[450px]"></img>
        </div>
        <div className="py-10">
          <p className="text-3xl underline">
            Sammanställning: 4 arbetsdagar, 15 aug -18 aug
          </p>
          <div className="mt-4">
            <div className="text-3xl  flex justify-between">
              <p className="text-3xl w-[300px] flex justify-between">
                Etableringskostnad:{" "}
              </p>
              <p className="">
                1500:-{" "}
                <span
                  className="underline text-blue-500 cursor-pointer"
                  onClick={() => setstartupcost(true)}
                >
                  (Vad i hela friden är detta?)
                </span>
              </p>
            </div>

            <div className="text-3xl w-[395px] flex justify-between">
              <p className="text-3xl w-[500px] flex justify-between">
                Dagshyra:
              </p>
              <p className="">500:-</p>
            </div>
            <div className="text-3xl w-[395px] flex justify-between">
              <p className="text-3xl w-[500px] flex justify-between">
                Pris per körtimme:
              </p>
              <p className="">150:-</p>
            </div>
          </div>
          <div className="mt-4">
            <p className="text-3xl text-start mt-5">Exempel:</p>
            <div className="text-3xl w-[395px] flex justify-between">
              <p className="text-3xl w-[500px] flex justify-between">
                Etableringskostnad
              </p>
              <p className="">1500:-</p>
            </div>
            <div className="text-3xl w-[395px] flex justify-between">
              <p className="text-3xl w-[500px] flex justify-between">
                4 x Dagshyra{" "}
              </p>
              <p className="">2000:-</p>
            </div>
            <div className="text-3xl w-[395px] flex justify-between">
              <p className="text-3xl w-[500px] flex justify-between underline">
                8h körning
              </p>
              <p className="underline">1200:-</p>
            </div>
            <div className="text-3xl w-[395px] flex justify-between">
              <p className="text-3xl w-[500px] flex justify-between">Summa</p>
              <p className="">4700:-</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <Button className="text-4xl text-black border-black px-4 bg-green-500 py-1 border-2 mt-2">
          Gå vidare!
        </Button>
      </div>
      {startupcost && <StartUpCost close={() => setstartupcost(false)} />}
    </>
  );
}
