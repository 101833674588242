import excavator from "../asset/img/excavv.webp";
import bulldozer from "../asset/img/bulldozer.avif";
import { Button } from "../components/Button";

export function Landing({ onNavigate }: { onNavigate: () => void }) {
  return (
    <>
      <div className="flex justify-center text-gray-500 mt-5">
        <p className="text-4xl text-center w-2/3">
          Vi hyr ut SMÅ elektriska grävmaskiner och hjullastare till
          privatpersoner i Stockholmsområdet
        </p>
      </div>
      <div className="flex justify-center items-center text-gray-500">
        <div className="flex items-center">
          <img src={excavator} alt="" className=" w-56"></img>
          <div className=" ml-2">
            <p className="text-xl">500:-/dag + 150:-/körtimme</p>
            <Button
              className="text-xl text-black border-black px-4 bg-green-500 py-1 border-2 mt-2"
              onClick={() => onNavigate()}
            >
              Hyr mig!
            </Button>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center text-gray-500">
        <div className="flex items-center">
          <img src={bulldozer} alt="" className=" w-56"></img>
          <div className="ml-2">
            <p className="text-xl">500:-/dag + 150:-/körtimme</p>
            <Button
              className="text-xl text-black border-black px-4 bg-green-500 py-1 border-2 mt-2"
              onClick={() => onNavigate()}
            >
              Hyr mig!
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
